@import "~@/styles/variables.scss";
.vab-ad {
  height: 30px;
  padding-right: $base-padding;
  padding-left: $base-padding;
  margin-bottom: -20px;
  line-height: 30px;
  cursor: pointer;

  a {
    color: #999;
  }
}
