/**
 * @description 全局加载动画
 */

@charset "utf-8";

@import "./spinner/dots.css";
@import "./spinner/gauge.css";
@import "./spinner/inner-circles.css";
@import "./spinner/plus.css";

$base-loading: ".vab-loading-type";

/* 自定义loading开始 */
#{$base-loading}1 {
  display: flex;
  width: 36px;
  height: 36px;
  margin: 0 auto 15px;
  border: 3px solid transparent;
  border-top-color: $base-color-blue;
  border-bottom-color: $base-color-blue;
  border-radius: 50%;
  animation: vabLoading1-0 0.8s linear infinite;
}

#{$base-loading}1::before {
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  content: "";
  border: 3px solid $base-color-blue;
  border-radius: 50%;
  animation: vabLoading1 0.5s alternate ease-in infinite;
}

@keyframes vabLoading1-0 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes vabLoading1 {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1.2);
  }
}

#{$base-loading}2 {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -10px;
  animation: vabLoading2 1s linear reverse infinite;
}

#{$base-loading}2::before {
  display: block;
  width: 36px;
  height: 36px;
  margin-top: -17px;
  margin-left: -18px;
  content: "";
  animation: vabLoading2 0.4s linear infinite;
}

#{$base-loading}2::after {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  margin-left: -4px;
  content: "";
  animation: vabLoading2 0.4s linear infinite;
}

#{$base-loading}2::before,
#{$base-loading}2,
#{$base-loading}2::after {
  position: absolute;
  top: 40%;
  left: 50%;
  border: 3px solid transparent;
  border-top-color: $base-color-blue;
  border-right-color: $base-color-blue;
  border-radius: 50%;
}

@keyframes vabLoading2 {
  to {
    transform: rotate(360deg);
  }
}

#{$base-loading}3 {
  display: inline-block;
  width: 2.5em;
  height: 3em;
  margin-bottom: 15px;
  border: 3px solid transparent;
  border-top-color: $base-color-blue;
  border-bottom-color: $base-color-blue;
  border-radius: 50%;
  animation: vabLoading3 2s ease infinite;
}

@keyframes vabLoading3 {
  50% {
    border-width: 8px;
    transform: rotate(360deg) scale(0.4, 0.33);
  }

  100% {
    border-width: 3px;
    transform: rotate(720deg) scale(1, 1);
  }
}

#{$base-loading}4 {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
  border: 8px solid transparent;
  border-bottom-color: $base-color-blue;
  border-left-color: $base-color-blue;
  border-radius: 50%;
  animation: vabLoading4 1s linear infinite normal;
}

#{$base-loading}4::after {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0;
  content: " ";
  border: 6px solid $base-color-blue;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
}

@keyframes vabLoading4 {
  0% {
    opacity: 0.2;
    transform: rotate(0deg);
  }

  50% {
    opacity: 1;
    transform: rotate(180deg);
  }

  100% {
    opacity: 0.2;
    transform: rotate(360deg);
  }
}

#{$base-loading}5 {
  display: block;
  width: 0;
  height: 0;
  margin: 0 auto 15px;
  border: solid 1.5em $base-color-blue;
  border-right: solid 1.5em transparent;
  border-left: solid 1.5em transparent;
  border-radius: 100%;
  animation: vabLoading5 1s linear infinite;
}

@keyframes vabLoading5 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#{$base-loading}6 {
  display: block;
  width: 0;
  height: 0;
  margin: 0 auto 25px auto;
  perspective: 200px;
}

#{$base-loading}6::before,
#{$base-loading}6::after {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  background: rgba(0, 0, 0, 0);
  animation: vabLoading6 0.5s infinite alternate;
}

#{$base-loading}6::before {
  left: 0;
}

#{$base-loading}6::after {
  right: 0;
  animation-delay: 0.15s;
}

@keyframes vabLoading6 {
  0% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transform: scale(1) translateY(0) rotateX(0deg);
  }

  100% {
    background: $base-color-blue;
    box-shadow: 0 25px 40px rgba($base-color-blue, 0.5);
    transform: scale(1.2) translateY(-25px) rotateX(45deg);
  }
}

#{$base-loading}7 {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto 15px auto;
  border: 2px solid $base-color-blue;
  border-top-color: rgba($base-color-blue, 0.2);
  border-right-color: rgba($base-color-blue, 0.2);
  border-bottom-color: rgba($base-color-blue, 0.2);
  border-radius: 100%;
  animation: vabLoading7 infinite 0.75s linear;
}

@keyframes vabLoading7 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#{$base-loading}8 {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto 15px auto;
  background-color: $base-color-blue;
  border-radius: 50%;
  box-shadow: 30px 0 0 0 $base-color-blue;
  transform: translateX(-15px);
}

#{$base-loading}8::after {
  position: absolute;
  top: 8px;
  left: 9px;
  width: 10px;
  height: 10px;
  content: "";
  background-color: $base-color-white;
  border-radius: 50%;
  box-shadow: 30px 0 0 0 $base-color-white;
  animation: vabLoading8 2s ease-in-out infinite alternate;
}

@keyframes vabLoading8 {
  0% {
    left: 9px;
  }

  100% {
    left: 1px;
  }
}

#{$base-loading}9 {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto 15px auto;
  border: 1px $base-color-blue solid;
  animation: vabLoading9 5s linear infinite;
}

#{$base-loading}9::after {
  position: absolute;
  top: -8px;
  left: 0;
  width: 4px;
  height: 4px;
  content: "";
  background-color: $base-color-blue;
  animation: vabLoading9_check 1s ease-in-out infinite;
}

@keyframes vabLoading9_check {
  25% {
    top: -8px;
    left: 22px;
  }

  50% {
    top: 22px;
    left: 22px;
  }

  75% {
    top: 22px;
    left: -9px;
  }

  100% {
    top: -7px;
    left: -9px;
  }
}

@keyframes vabLoading9 {
  0% {
    box-shadow: inset 0 0 0 0 rgba($base-color-blue, 0.5);
    opacity: 0.5;
  }

  100% {
    box-shadow: inset 0 -20px 0 0 $base-color-blue;
  }
}

/* 自定义loading结束 */
