@import "~@/styles/variables.scss";
.el-drawer__wrapper {
  outline: none !important;

  * {
    outline: none !important;
  }
}

.vab-color-picker {
  .el-color-dropdown__link-btn {
    display: none;
  }
}
