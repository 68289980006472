@import "~@/styles/variables.scss";
.avatar-dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 50px;
  padding: 0;

  .user-avatar {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
  }

  .user-name {
    position: relative;
    margin-left: 5px;
    margin-left: 5px;
    cursor: pointer;
  }

  .demo-ruleForm {
    font-size: 16px;
    width: 80%;
    height: 200px;
    background-color: rgba(128, 128, 128, 0.5);
    margin-top: 28px;
    padding-left: 30px;
  }
}
